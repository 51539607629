import React from "react"

import { useRedditClient } from "../utils/hooks"

const Test = () => {
  const [submissions, setSubmissions] = React.useState([])
  const client = useRedditClient()
  const getSubs = async () => {
    const user_submissions = await client.getUserSubmissions()
    setSubmissions(user_submissions)
  }

  React.useEffect(() => {
    // setClient(client)
    if (client) {
      getSubs()

      console.log(submissions)
    }
  },[])

  const items = submissions.map(subreddit => {
    return <>{subreddit.data.display_name}</>
  })

  return <>{items}</>
// return <>Test</>
}

export default Test
